import React from "react"
//import ReactMarkdown from 'react-markdown'
//import rehypeRaw from 'rehype-raw'
import { graphql } from "gatsby"
import PageHeader from "../components/page-header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Publication from "../components/research/pub"
import Project from "../components/research/project"
import SubHeader from "../components/sub-header"

const Research = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const pubs = data.pubs.edges
  const msc = data.msc.edges
  const proj = data.proj.edges
//  const post = data.markdownRemark

  return (
    <Layout title={siteTitle}>
      <SEO title="Research" />
      <PageHeader headerText="Research" />
      <div className="avenir center ph3 ph4-m ph5-l mw7 bl br b--near-white">
        <SubHeader headerText="Publications"/>
        {pubs.map(({ node }) => {
          return (
            <Publication 
              title={node.frontmatter.title}
              authors={node.internal.content}
              venue={node.frontmatter.venue}
              doiarxiv={node.frontmatter.doiarxiv}
              displayUrl={node.frontmatter.displayUrl}
              url={node.frontmatter.url}
            />
          )
        })}
        <SubHeader headerText="Masters' Theses"/>
        {msc.map(({ node }) => {
          return (
            <Project
              title={node.frontmatter.title}
              authors={node.internal.content}
              displayUrl={node.frontmatter.displayUrl}
              url={node.frontmatter.url}
              year={node.frontmatter.year}
            />
          )
        })}
        <SubHeader headerText="Undergraduate Projects"/>
        {proj.map(({ node }) => {
          return (
            <Project 
              title={node.frontmatter.title}
              authors={node.internal.content}
              displayUrl={node.frontmatter.displayUrl}
              url={node.frontmatter.url}
              year={node.frontmatter.year}
            />
          )
        })}
        {/*<ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          className="mw-80 ph3 ph4-m ph5-l avenir fw2 measure-wide db center f5 f4-ns lh-copy"
          children={post.internal.content}
        />*/}
      </div>
    </Layout>
  )
}

export default Research

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    pubs: allMarkdownRemark(
      filter: { 
        frontmatter: { templateKey: { eq: "pub" } }
      }
      sort: { fields: [frontmatter___year], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            venue
            doiarxiv
            displayUrl
            url
          }
          internal {
            content
          }
        }
      }
    }
    msc: allMarkdownRemark(
      filter: { 
        frontmatter: { templateKey: { eq: "msc" } }
      }
      sort: { fields: [frontmatter___year], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            displayUrl
            url
            year
          }
          internal {
            content
          }
        }
      }
    }
    proj: allMarkdownRemark(
      filter: { 
        frontmatter: { templateKey: { eq: "proj" } }
      }
      sort: { fields: [frontmatter___year], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            displayUrl
            url
            year
          }
          internal {
            content
          }
        }
      }
    }
  }
`

{/*export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "publications-page" } }) {
      html
      internal {
        content
      }
    }
  }
`*/}
